<template>
  <div>
    <template>
      <el-select
        v-model="value"
        filterable
        clearable
        remote
        reserve-keyword
        :placeholder="placeholder ? placeholder : '请输入关键词'"
        :remote-method="remoteMethod"
        @change="selectAddress"
      >
        <el-option
          v-for="item in options"
          :key="item.title"
          :label="item.title + '(' + item.address + ')'"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </template>
    <div id="map"></div>
  </div>
</template>

<script>
export default {
  props: ["lat", "lon", "locationName"],
  data() {
    return {
      keyword: "",
      value: "",
      options: [],
      placeholder: "",
    };
  },
  computed: {
    // 初始化地图
    map() {
      // 定义地图中心点坐标
      let center = new window.TMap.LatLng(22.826675305, 108.315303012);
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      let mapVal = new window.TMap.Map(document.getElementById("map"), {
        center: center, // 设置地图中心点坐标
        zoom: 17, // 设置地图缩放级别
        pitch: 20, // 设置俯仰角
        rotation: 45, // 设置地图旋转角度
      });
      return mapVal;
    },
    // 初始化关键字搜索
    suggest() {
      let suggest = new window.TMap.service.Suggestion({
        // 新建一个关键字输入提示类
        pageSize: 10, // 返回结果每页条目数
        region: "南宁", // 限制城市范围
        regionFix: false, // 搜索无结果时是否固定在当前城市
      });
      return suggest;
    },
    //初始化marker图层
    markerLayer() {
      var markerLayer = new window.TMap.MultiMarker({
        id: "marker-layer",
        map: this.map,
      });
      return markerLayer;
    },
  },
  methods: {
    // 选择地址事件
    selectAddress(val) {
      if (val) {
        let location = this.options.filter((item) => item.id == val)[0];
        // 改变地图中心点位置
        this.map.setCenter(location.location);
        // 添加标记
        this.markerLayer.add({
          position: location.location,
        });

        this.$emit("addressInfo", location);
      }
    },
    // 关键字地点搜索
    remoteMethod(keyword) {
      this.options = [];
      if (keyword !== "") {
        setTimeout(() => {
          this.suggest
            .getSuggestions({
              keyword: keyword,
              location: this.map.getCenter(),
            })
            .then((result) => {
              if (result.data.length > 0) {
                for (let item of result.data) {
                  this.options.push(item);
                }
              }
            });
        }, 200);
      }
    },
    init(lat, lon, location) {
      // 改变地图中心点位置
      this.map.setCenter({ lat: lat, lng: lon });
      // 添加标记
      let latlng = {
        lat: lat,
        lng: lon,
        height: 0,
      };
      this.markerLayer.add({
        position: latlng,
      });
      this.placeholder = location;
    },
  },
  mounted() {
    console.log(this.markerLayer);

    // 改变地图中心点位置
    // this.map.setCenter({ lat: this.$parent.lat, lon: this.$parent.lon });
    // // 添加标记
    // this.markerLayer.add({
    //   position: { lat: this.$parent.dalat, lon: this.$parent.lon },
    // });
  },
};
</script>
<style>
#map {
  width: 100%;
  height: 400px;
  margin-top: 10px;
}
</style>
