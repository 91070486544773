<template>
  <div class="mod-config">
    <el-form
      :inline="true"
      :model="dataForm"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item>
        <el-input
          v-model="dataForm.keyword"
          placeholder="输入关键字搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-select
        v-model="dataForm.cid"
        clearable
        :placeholder="companyName ? companyName : '按代理商筛选'"
        filterable
        @focus="companySelectFocus"
        style="margin-right: 10px"
      >
        <el-option
          v-for="item in companyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataForm.type"
        clearable
        placeholder="按类型筛选"
        style="margin-right: 10px"
      >
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-cascader
        :options="provinces"
        :props="{ checkStrictly: true }"
        clearable
        @focus="provinceSelectFocus"
        placeholder="按区域搜索"
        @change="changeSelect"
        style="margin-right: 10px"
      ></el-cascader>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        width="60px"
        prop="id"
        header-align="center"
        align="center"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="所属代理商"
      >
      </el-table-column>
      <el-table-column
        prop="managerName"
        header-align="center"
        align="center"
        label="管理员"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="商家名"
      >
      </el-table-column>
      <el-table-column
        prop="tag"
        header-align="center"
        align="center"
        label="标签"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="introduce"
        header-align="center"
        align="center"
        label="商家介绍"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="province"
        header-align="center"
        align="center"
        label="省"
      >
      </el-table-column>
      <el-table-column
        prop="city"
        header-align="center"
        align="center"
        label="市"
      >
      </el-table-column>
      <el-table-column
        prop="county"
        header-align="center"
        align="center"
        label="区（县）"
      >
      </el-table-column>
      <el-table-column
        prop="address"
        header-align="center"
        align="center"
        label="详细地址"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="类型"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">4s店</span>
          <span v-if="scope.row.type == 1">二手车</span>
          <span v-if="scope.row.type == 2">洗养</span>
          <span v-if="scope.row.type == 3">加油站</span>
          <span v-if="scope.row.type == 4">检测站</span>
          <span v-if="scope.row.type == 5">维修站</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="proportion"
        header-align="center"
        align="center"
        label="分成比例"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.proportion * 100 }}%</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="score"
        header-align="center"
        align="center"
        label="评分"
      >
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>

      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="100"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./business-add-or-update";
import {
  getBusinessList,
  deleteBusinessById,
  getCompanyList,
} from "../../api/api";
export default {
  data() {
    return {
      // 查询条件
      dataForm: {
        keyword: "",
        cid: "", // 代理商选择器绑定值
        type: "", // 类型选择器绑定值
        province: "",
        city: "",
        county: "",
      },
      //  代理商选择器列表
      companyOptions: [],
      // 代理商选择器展示
      companyName: "",
      // 类型选择器列表
      typeOptions: [
        {
          value: 0,
          label: "4s店",
        },
        {
          value: 1,
          label: "二手车",
        },
        {
          value: 2,
          label: "洗养",
        },
        {
          value: 3,
          label: "加油站",
        },
        {
          value: 4,
          label: "检测站",
        },
        {
          value: 5,
          label: "维修站",
        },
      ],
      provinces: [], // 省市县数据
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
    };
  },

  components: {
    AddOrUpdate,
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo.cid) {
      this.dataForm.cid = userInfo.cid;
      this.companyOptions.push({
        value: this.dataForm.cid,
        label: userInfo.company.name,
      });
      this.companyName = userInfo.company.name;
    }
    this.getDataList();
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;
      getBusinessList(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 代理商选择框获得焦点事件
    companySelectFocus() {
      if (this.companyOptions.length > 0) {
        return;
      }
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.cid) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.companyOptions = [];
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 区域选择器获得焦点事件
    provinceSelectFocus() {
      if (this.provinces == null || this.provinces.length < 1) {
        // 省市县json数据
        const regions = require("../../public/regions.json");
        for (let province of regions) {
          let p = {};
          p.value = province.name;
          p.label = province.name;
          p.children = [];

          for (let city of province.children) {
            let c = {};
            c.value = city.name;
            c.label = city.name;

            if (city.children) {
              c.children = [];
              for (let county of city.children) {
                let cou = {};
                cou.value = county.name;
                cou.label = county.name;
                c.children.push(cou);
              }
            }

            p.children.push(c);
          }
          this.provinces.push(p);
        }
      }
    },
    // 选择省市县
    changeSelect(item) {
      if (item[2]) {
        this.dataForm.province = item[0];
        this.dataForm.city = item[1];
        this.dataForm.county = item[2];
      } else if (item[1]) {
        this.dataForm.province = item[0];
        this.dataForm.city = item[1];
        this.dataForm.county = "";
      } else if (item[0]) {
        this.dataForm.province = item[0];
        this.dataForm.city = "";
        this.dataForm.county = "";
      } else {
        this.dataForm.province = "";
        this.dataForm.city = "";
        this.dataForm.county = "";
      }
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteBusinessById({
          id,
        }).then((data) => {
          if (data && data.code === 0) {
            this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>
