<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="36%"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="所属代理商" prop="cid">
        <el-select
          style="width: 500px"
          v-model="companyName"
          clearable
          :placeholder="!dataForm.id ? '请输入关键词搜索' : companyName"
          @change="changeCompany"
          filterable
          @focus="companySelectFocus"
        >
          <el-option
            v-for="item in companyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="管理员" prop="uid">
        <el-select
          style="width: 500px"
          v-model="managerName"
          filterable
          remote
          reserve-keyword
          :placeholder="!dataForm.id ? '请输入关键词搜索' : managerName"
          :remote-method="remoteManager"
          @change="changeManager"
          :loading="loading"
        >
          <el-option
            v-for="item in userList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商家名" prop="name">
        <el-input
          v-model="dataForm.name"
          placeholder="商家名"
          style="width: 500px"
        ></el-input>
      </el-form-item>
      <el-form-item label="商家分成比例" prop="proportion">
        <el-input
          style="width: 500px"
          v-model="dataForm.proportion"
          placeholder="商家分成比例（最多两位小数，最大不能超过1，比如分成75%就输入0.75）"
        ></el-input>
      </el-form-item>
      <el-form-item label="标签" prop="tag">
        <el-tag
          :key="tag"
          v-for="tag in dynamicTags"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput"
          >+新增标签
        </el-button>
      </el-form-item>
      <el-form-item label="图片" prop="picture">
        <el-upload
          class="upload-demo"
          action="https://www.jsfgx.online/newcar/newcar/common/upload"
          :on-success="uploadSuccess"
          :on-remove="removePic"
          :file-list="fileList"
          :headers="uploadHeaders"
          list-type="picture"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="商家介绍" prop="introduce">
        <el-input
          style="width: 500px"
          v-model="dataForm.introduce"
          placeholder="商家介绍"
        ></el-input>
      </el-form-item>
      <el-form-item label="商家评分" prop="score" v-if="showScore">
        <el-input
          style="width: 500px"
          v-model="dataForm.score"
          placeholder="商家评分（5分最高，最多一位小数）"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否置顶" prop="isTop">
        <el-switch
          v-model="topValue"
          active-text="是"
          inactive-text="否"
          @change="topChange"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="定位" prop="location">
        <t-map v-on:addressInfo="addressInfo" ref="tMap"></t-map>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input
          v-model="dataForm.address"
          placeholder="请输入详细地址"
          style="width: 500px"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select
          style="width: 500px"
          v-model="dataForm.type"
          clearable
          :placeholder="typeName"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="品牌" prop="brand">
        <el-select
          style="width: 500px"
          v-model="dataForm.brand"
          clearable
          :placeholder="dataForm.brand ? dataForm.brand : '请选择品牌'"
          filterable
          @focus="brandSelectFocus"
        >
          <el-option
            v-for="item in brandOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  getCompanyList,
  getUserList,
  getBusinessInfo,
  saveBusiness,
  updateBusiness,
} from "../../api/api";
import TMap from "../../src/components/TMap";
export default {
  components: {
    TMap,
  },
  data() {
    return {
      topValue: false, // 置顶开关绑定值
      visible: false,
      //  代理商选择器列表
      companyOptions: [],
      // 用户选择器列表
      userList: [],
      dynamicTags: [], // 标签列表
      inputVisible: false,
      inputValue: "",
      loading: false,
      // 图片列表
      fileList: [],
      // 类型选择项
      typeOptions: [
        {
          value: 0,
          label: "4s店",
        },
        {
          value: 1,
          label: "二手车",
        },
        {
          value: 2,
          label: "洗养",
        },
        {
          value: 3,
          label: "加油站",
        },
        {
          value: 4,
          label: "检测站",
        },
        {
          value: 5,
          label: "维修站",
        },
      ],
      // 品牌选择列表
      brandOptions: [],
      // 类型回显
      typeName: "",
      // 代理商名回显
      companyName: "",
      // 管理员名字回显
      managerName: "",
      dataForm: {
        id: 0,
        cid: "",
        uid: "",
        name: "",
        tag: "",
        picture: "",
        introduce: "",
        lon: "",
        lat: "",
        isTop: 0,
        province: "",
        city: "",
        county: "",
        address: "",
        type: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
        brand: "",
        score: "",
        proportion: "",
      },
      dataRule: {
        cid: [{ required: true, message: "所代理商不能为空", trigger: "blur" }],
        uid: [{ required: true, message: "管理员不能为空", trigger: "blur" }],
        name: [{ required: true, message: "商家名不能为空", trigger: "blur" }],
        introduce: [
          { required: true, message: "商家介绍不能为空", trigger: "blur" },
        ],
        proportion: [
          { required: true, message: "分成比例不能为空", trigger: "blur" },
        ],
        address: [
          { required: true, message: "详细地址不能为空", trigger: "blur" },
        ],
        type: [
          {
            required: true,
            message: "类型不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    // 上传时的请求头
    uploadHeaders() {
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      return { Token: token };
    },
    // 是否显示评分
    showScore() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.type == 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // 选择用户的事件
    changeManager(value) {
      this.dataForm.uid = value;
    },
    // 选择代理商的事件
    changeCompany(value) {
      this.dataForm.cid = value;
    },
    // 品牌选择框获得焦点事件
    brandSelectFocus() {
      if (this.brandOptions.length < 1) {
        const brands = require("../../public/carBrand.json");
        for (let item of brands) {
          let nav = {};
          nav.value = item.title;
          nav.name = item.title;
          nav.disabled = true;
          this.brandOptions.push(nav);
          for (let brand of item.lists) {
            this.brandOptions.push({
              value: brand,
              name: brand,
              disabled: false,
            });
          }
        }
      }
    },
    // 选择定位触发事件
    addressInfo(data) {
      this.dataForm.address = "";
      this.dataForm.lat = data.location.lat;
      this.dataForm.lon = data.location.lng;
      this.dataForm.province = data.province;
      this.dataForm.city = data.city;
      this.dataForm.county = data.district;
      this.dataForm.address = data.address;
    },
    // 上传图片成功的事件
    uploadSuccess(response) {
      if (response.code === 0) {
        this.fileList.push({
          name: response.data,
          url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + response.data,
        });
      }
    },
    // 删除图片事件
    removePic(file, fileList) {
      if (file) {
        this.fileList = fileList;
      }
    },
    // 关闭标签时触发的事件
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    // 新增标签
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 确认添加标签
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // 代理商选择器获得焦点事件
    companySelectFocus() {
      if (this.companyOptions.length > 0) {
        return;
      }
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.type == 1) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.companyOptions = [];
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 搜索用户
    remoteManager(query) {
      if (query !== "") {
        this.loading = true;
        this.userList = [];
        setTimeout(() => {
          // 请求用户列表，封装数据
          let requestData = { keyword: query };
          getUserList(requestData).then((data) => {
            if (data && data.code === 0) {
              for (let item of data.data.list) {
                let userOps = {};
                userOps.value = item.id;
                userOps.label =
                  item.nickName +
                  "(" +
                  (item.realName ? item.realName : "未实名") +
                  ")";
                this.userList.push(userOps);
              }
            } else {
              this.userList = [];
            }
          });
        }, 200);
        this.loading = false;
      }
    },
    // 是否置顶开关回调
    topChange(item) {
      if (item) {
        this.dataForm.isTop = 1;
      } else {
        this.dataForm.isTop = 0;
      }
    },
    // 初始化数据回显
    init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      this.dynamicTags = [];
      this.fileList = [];
      this.companyName = "";
      this.managerName = "";
      this.topValue = false;

      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo.type == 1) {
          this.dataForm.cid = userInfo.company.id;
          this.companyOptions.push({
            value: this.dataForm.cid,
            label: userInfo.company.name,
          });
          this.companyName = userInfo.company.name;
        }
        if (this.dataForm.id) {
          getBusinessInfo({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
              if (this.dataForm.type === 0) {
                this.typeName = "4s店";
              } else if (this.dataForm.type === 1) {
                this.typeName = "二手车";
              } else if (this.dataForm.type === 2) {
                this.typeName = "洗养";
              } else if (this.dataForm.type === 3) {
                this.typeName = "加油站";
              } else if (this.dataForm.type === 4) {
                this.typeName = "检测站";
              } else if (this.dataForm.type === 5) {
                this.typeName = "维修站";
              }

              if (this.dataForm.isTop == 0) {
                this.topValue = false;
              } else if (this.dataForm.isTop == 1) {
                this.topValue = true;
              }

              this.companyName = data.data.companyName;
              this.managerName = data.data.managerName;

              for (let tag of data.data.tag.split("_")) {
                if (tag) {
                  this.dynamicTags.push(tag);
                }
              }

              for (let pic of data.data.picture.split("_")) {
                if (pic) {
                  let picture = {
                    name: pic,
                    url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + pic,
                  };
                  this.fileList.push(picture);
                }
              }

              this.$refs.tMap.init(
                data.data.lat,
                data.data.lon,
                data.data.address
              );
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      if (this.dataForm.score > 5 || this.dataForm.score < 0) {
        this.$message.error("请正确输入评分");
        return;
      }
      if (this.dataForm.proportion > 1 || this.dataForm.proportion < 0) {
        this.$message.error("请正确输入分成比例");
        return;
      }
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;
          let pics = "";
          for (let pic of this.fileList) {
            pics =
              pics +
              pic.url.substring(pic.url.lastIndexOf("/") + 1, pic.url.length) +
              "_";
          }
          pics = pics.substring(0, pics.length - 1);
          requestDate.picture = pics;

          let tags = "";
          for (let tag of this.dynamicTags) {
            tags = tags + tag + "_";
          }
          tags = tags.substring(0, tags.length - 1);
          requestDate.tag = tags;
          if (this.dataForm.id) {
            // 修改
            updateBusiness(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            saveBusiness(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
